import {
  overview,
  action,
  management,
  coins,
  roles,
  settings,
  manage,
  user,
  trans,
  bus,
  invoice,
  savings,
  pos,
  cards,
  expense,
  transaction,
  physicalcard,
  virtual,
  employee,
  totalinvoice,
  totalTrans,
  totalPos,
  suspendIcon,
  blockIcon,
  bankNote,
  inflowIcon,
  outflowIcon,
  profileIcon,
  userIcon,
  logout,
  personIcon,
  viewIcon,
  moneyIcon,
  viewLogo,
  auditIcon,
  deleteIcon,
  veiw_detail_icon,
  update_icon,
  delete_icon,
} from '../../assets/exports';

import { slide1, slide2, slide3, slide4 } from '../../assets/exports';

export const activeFree = [
  { name: 'View Profile', img: '/assets/viewPfp.svg' },
  { name: 'Edit user Details', img: '/assets/editPfp.svg' },
  { name: 'View Transaction History', img: '/assets/viewTran.svg' },
  { name: 'Suspend Account', img: '/assets/suspend.svg' },
  { name: 'Unsuspend Account', img: '/assets/suspend.svg' },
  { name: 'Block Account', img: '/assets/block.svg' },
];

export const quickOptions = [
  { name: 'View Profile', img: '/assets/viewPfp.svg' },
  { name: 'Verify Account', img: '/assets/verify.svg' },
  { name: 'Suspend Account', img: '/assets/suspend.svg' },
  { name: 'Unsuspend Account', img: '/assets/suspend.svg' },
  { name: 'Block Account', img: '/assets/block.svg' },
];

export const test = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
];

export const activeCard = [
  { name: 'View Card Details', img: '/assets/viewTran.svg' },
  { name: 'Update Card Details', img: '/assets/updateCard.svg' },
  { name: 'Audit Trail', img: '/assets/audit.svg' },
  { name: 'Unsuspend Card', img: '/assets/suspendCard.svg' },
  { name: 'Suspend Card', img: '/assets/suspendCard.svg' },
  { name: 'Block Card', img: '/assets/block.svg' },
];

export const blockedCard = [
  { name: 'View Card Details', img: '/assets/viewTran.svg' },
  { name: 'Audit Trail', img: '/assets/audit.svg' },
  { name: 'Suspend Card', img: '/assets/suspendCard.svg' },
  { name: 'Unblock Card', img: '/assets/unblock.svg' },
];

export const blockedFree = [
  { name: 'View Profile', img: '/assets/viewPfp.svg' },
  { name: 'View Transaction History', img: '/assets/viewTran.svg' },
  { name: 'Suspend Account', img: '/assets/suspend.svg' },
  { name: 'Unblock Account', img: '/assets/unblock.svg' },
];

export const slideshow = [slide1, slide2, slide3, slide4];

export const siderBarData = [
  {
    id: 0,
    name: 'Overview',
    path: '/dashboard',
    icon: overview,
  },
  {
    id: 1,
    name: 'Quick Actions',
    path: '/quick-action',
    icon: action,
  },

  {
    id: 2,
    name: 'Transaction',
    path: '/payments',
    icon: coins,
  },
  {
    id: 3,
    name: 'Management',
    path: '/management',
    icon: management,
    sub: [
      {
        id: 1,
        name: 'User Management',
        path: '/management/user-mgt',
        icon: user,
      },

      // {
      //   id: 2,
      //   name: "Transaction Mgt",
      //   path: "/management/transaction-mgt",
      //   icon: trans,
      // },

      {
        id: 3,
        name: 'Bus. Registration Mgt',
        path: '/management/business-mgt',
        icon: bus,
      },

      {
        id: 4,
        name: 'Invoice Management',
        path: '/management/invoice-mgt',
        icon: invoice,
      },

      {
        id: 5,
        name: 'Savings Plan Mgt',
        path: '/management/savings-mgt',
        icon: savings,
      },

      {
        id: 6,
        name: 'POS Management',
        path: '/management/pos-mgt',
        icon: pos,
      },

      {
        id: 7,
        name: 'Cards Management',
        path: '/management/cards-mgt',
        icon: cards,
      },

      {
        id: 8,
        name: 'Expense Management',
        path: '/management/expense-mgt',
        icon: expense,
      },
    ],
  },
];

export const general = [
  {
    id: 0,
    name: 'Roles & Permission',
    path: '/roles',
    icon: roles,
  },
  {
    id: 1,
    name: 'Manage Teams',
    path: '/teams',
    icon: manage,
  },

  {
    id: 1,
    name: 'Settings',
    path: '/settings',
    icon: settings,
  },
];

export const teamData = [
  {
    id: 1,
    firstName: 'John',
    lastName: 'Doe',
    email: 'john.doe@example.com',
    phoneNumber: '08045678901',
    businessId: '12345',
    role: 'Customer Support',
    status: 'online',
    lastActivity: 'Jan 10, 2034, 14:45 AM',
  },
  {
    id: 2,
    firstName: 'Jane',
    lastName: 'Smith',
    email: 'jane.smith@example.com',
    phoneNumber: '08056789012',
    businessId: '23456',
    role: 'Operations',
    status: 'offline',
    lastActivity: 'Dec 35, 2010 05:45 AM',
  },
  {
    id: 3,
    firstName: 'Alice',
    lastName: 'Johnson',
    email: 'alice.johnson@example.com',
    phoneNumber: '08067890123',
    businessId: '34567',
    role: 'Customer Support',
    status: 'online',
    lastActivity: 'Jan 10, 2034, 14:45 AM',
  },
  {
    id: 4,
    firstName: 'Bob',
    lastName: 'Brown',
    email: 'bob.brown@example.com',
    phoneNumber: '08078901234',
    businessId: '45678',
    role: 'Finance',
    status: 'offline',
    lastActivity: 'Apr, 2012, 10:45 PM',
  },
];

export const analytics = [
  {
    id: 1,
    title: 'Total Business Acc.',
    amount: '23,349',
  },
  {
    id: 2,
    title: 'Total Freelance Acc.',
    amount: '23,349',
  },
  {
    id: 3,
    title: 'Total Bus. Reg Request ',
    amount: '23,349',
  },
];

export const transactions = [
  {
    amount: 7531000,
    date: '2024-07-01',
    accountName: 'John Doe',
    bank: 'ABC Bank',
    typeOfAccount: 'Savings',
    transactionId: 'TXN001',
    category: 'DEBIT',
    status: 'Successful',
    transactionMode: 'Online',
    time: '3:30pm',
    accountNumber: '1828394048',
  },
  {
    amount: 50000,
    date: '2024-07-02',
    accountName: 'Jane Smith',
    bank: 'XYZ Bank',
    typeOfAccount: 'Current',
    transactionId: 'TXN002',
    category: 'Shopping',
    status: 'Failed',
    transactionMode: 'POS',
    time: '3:30pm',
    accountNumber: '1828394048',
  },
  {
    amount: 1500000,
    date: '2024-07-03',
    accountName: 'Alice Johnson',
    bank: 'DEF Bank',
    typeOfAccount: 'Salary',
    transactionId: 'TXN003',
    category: 'Investment',
    status: 'Successful',
    transactionMode: 'Online',
    time: '3:30pm',
    accountNumber: '1828394048',
  },
  // Add more transactions as needed
];

export const stats = [
  {
    id: 1,
    title: 'Total Transaction Value',
    amount: '23,349',
  },
  {
    id: 2,
    title: 'Total Inflow Value',
    amount: '23,349',
  },
  {
    id: 3,
    title: 'Total Outflow Value',
    amount: '23,349',
  },
];

export const volume = [
  {
    id: 1,
    title: 'Total Physical Card',
    amount: '23,349',
    icon: physicalcard,
  },
  {
    id: 2,
    title: 'Total Virtual Card',
    amount: '23,349',
    icon: virtual,
  },
];

export const total = [
  {
    id: 1,
    title: 'Total No. of Employees',
    amount: '23,349',
    icon: employee,
  },

  {
    id: 2,
    title: 'Total Invoice',
    amount: '23,349',
    icon: totalinvoice,
  },
  {
    id: 3,
    title: 'Total Transactions',
    amount: '23,349',
    icon: totalTrans,
  },
  {
    id: 4,
    title: 'Total POS terminals',
    amount: '23,349',
    icon: totalPos,
  },
];

export const reportFilter = ['7 days'];

export const account = [
  'All Account',
  'Business Reg Request',
  'Virtual Cards',
  'Physical Cards',
  'Pos Request',
];

export const custHeaders = [
  'Account Name',
  'Email Address',
  'Status',
  'Transaction',
  '',
];

export const cusData = [
  {
    name: 'School Name',
    email: 'cathbaha1@gmail.com',
    status: 'Verified',
    transaction: 'view all transactions',
  },
  {
    name: 'School Name',
    email: 'cathbaha1@gmail.com',
    status: 'Verified',
    transaction: 'view all transactions',
  },
  {
    name: 'School Name',
    email: 'cathbaha1@gmail.com',
    status: 'Suspended',
    transaction: 'view all transactions',
  },
  {
    name: 'School Name',
    email: 'cathbaha1@gmail.com',
    status: 'Unverified',
    transaction: 'view all transactions',
  },

  {
    name: 'School Name',
    email: 'cathbaha1@gmail.com',
    status: 'Verified',
    transaction: 'view all transactions',
  },
  {
    name: 'School Name',
    email: 'cathbaha1@gmail.com',
    status: 'Verified',
    transaction: 'view all transactions',
  },
  {
    name: 'School Name',
    email: 'cathbaha1@gmail.com',
    status: 'Verified',
    transaction: 'view all transactions',
  },
  {
    name: 'School Name',
    email: 'cathbaha1@gmail.com',
    status: 'Verified',
    transaction: 'view all transactions',
  },
  {
    name: 'School Name',
    email: 'cathbaha1@gmail.com',
    status: 'Verified',
    transaction: 'view all transactions',
  },
  {
    name: 'School Name',
    email: 'cathbaha1@gmail.com',
    status: 'Verified',
    transaction: 'view all transactions',
  },
  {
    name: 'School Name',
    email: 'cathbaha1@gmail.com',
    status: 'Verified',
    transaction: 'view all transactions',
  },
  {
    name: 'School Name',
    email: 'cathbaha1@gmail.com',
    status: 'Verified',
    transaction: 'view all transactions',
  },
  {
    name: 'School Name',
    email: 'cathbaha1@gmail.com',
    status: 'Verified',
    transaction: 'view all transactions',
  },
  {
    name: 'School Name',
    email: 'cathbaha1@gmail.com',
    status: 'Verified',
    transaction: 'view all transactions',
  },
  {
    name: 'School Name',
    email: 'cathbaha1@gmail.com',
    status: 'Verified',
    transaction: 'view all transactions',
  },
];

export const cusAct = [
  {
    id: 1,
    title: 'View Details',
    //   icon: note,
  },
];

export const updateAct = [
  {
    id: 1,
    title: 'Update',
    //   icon: note,
  },
];

export const profileActions = [
  {
    id: 1,
    name: 'Suspend Account',
    icon: suspendIcon,
  },
  {
    id: 2,
    name: 'Block Account',
    icon: blockIcon,
  },
];

export const profileTab = [
  'Virtual card',
  'Physical Card',
  'Bank Account',
  'Identities',
];

export const virtualCardHeader = [
  'cardholder name',
  'Balance',
  'Card Details',
  'Status',
  '',
];

export const physicalCardHeader = [
  'cardholder name',
  'Balance ',
  'Transactions',
  'Status',
];

export const bankAccHeader = [
  'Account name',
  'Account Number ',
  'Bank name',
  'Status',
];
export const identities = [
  'Full name',
  'Identification Type',
  'Identification',
  'Status',
];

export const virtualCardData = [
  {
    name: 'Anifowoshe Mayowa',
    bal: '8,134,960.00',
    details: 'View card details',
    status: 'Active',
  },
  {
    name: 'Anifowoshe Mayowa',
    bal: '8,134,960.00',
    details: 'View card details',
    status: 'Suspend',
  },
  {
    name: 'Anifowoshe Mayowa',
    bal: '8,134,960.00',
    details: 'View card details',
    status: 'Inactive',
  },
  {
    name: 'Anifowoshe Mayowa',
    bal: '8,134,960.00',
    details: 'View card details',
    status: 'Blocked',
  },
];

export const viewEditDelete = [
  {
    name: 'View Details',
    icon: veiw_detail_icon,
  },
  // {
  //   name: "Update Details",
  //   icon: update_icon,
  // },
  {
    name: 'Delete Team',
    icon: delete_icon,
  },
];

export const physicalCardData = [
  {
    name: 'Anifowoshe Mayowa',
    bal: '8,134,960.00',
    transaction: 'View transactions',
    status: 'Active',
  },
  {
    name: 'Anifowoshe Mayowa',
    bal: '8,134,960.00',
    transaction: 'View transactions',
    status: 'Suspend',
  },
  {
    name: 'Anifowoshe Mayowa',
    bal: '8,134,960.00',
    transaction: 'View transactions',
    status: 'Inactive',
  },
  {
    name: 'Anifowoshe Mayowa',
    bal: '8,134,960.00',
    transaction: 'View transactions',
    status: 'Blocked',
  },
];

export const bankAccData = [
  {
    name: 'Anifowoshe Mayowa',
    accNo: '00987985678',
    bankName: '9PSB',
    status: 'Active',
  },
  {
    name: 'Anifowoshe Mayowa',
    accNo: '00987985678',
    bankName: '9PSB',
    status: 'Suspend',
  },
  {
    name: 'Anifowoshe Mayowa',
    accNo: '00987985678',
    bankName: '9PSB',
    status: 'Inactive',
  },
  {
    name: 'Anifowoshe Mayowa',
    accNo: '00987985678',
    bankName: '9PSB',
    status: 'Blocked',
  },
];

export const identitiesData = [
  {
    name: 'Anifowoshe Mayowa',
    idTyp: 'NIN',
    id: '223DFH7980',
    status: 'Verified',
  },
  {
    name: 'Anifowoshe Mayowa',
    idTyp: 'NIN',
    id: '223DFH7980',
    status: 'Suspend',
  },
  {
    name: 'Anifowoshe Mayowa',
    idTyp: 'NIN',
    id: '223DFH7980',
    status: 'N/A',
  },
  {
    name: 'Anifowoshe Mayowa',
    idTyp: 'NIN',
    id: '223DFH7980',
    status: 'Blocked',
  },
];

export const accountTabs = [
  'All accounts',
  'Business Reg. Requests',
  'Virtual Cards',
  'Physical Cards',
  'POS Request',
];

export const accountFil = [
  'All',
  'Verified',
  'Unverified',
  'Suspend',
  'Blocked',
];

export const categoriesFilter = [
  'All categories',
  'Debit',
  'Credit',
  'Bill payment',
  'Charges',
  'Airtime',
];

export const accTrans = [
  {
    name: 'CrestPeak Ventures',
    accNo: '0093456782',
    amount: ' 8,134,960',
    accName: 'Operations Account',
    type: 'Main Account',
  },
  {
    name: 'CrestPeak Ventures',
    accNo: '0093456782',
    amount: ' 8,134,960',
    accName: 'Operation Account',
    type: 'Sub Account',
  },
  {
    name: 'CrestPeak Ventures',
    accNo: '0093456782',
    amount: ' 8,134,960',
    accName: 'Operation Account',
    type: 'Sub Account',
  },
  {
    name: 'CrestPeak Ventures',
    accNo: '0093456782',
    amount: ' 8,134,960',
    accName: 'Operation Account',
    type: 'Sub Account',
  },
];

export const transValues = [
  {
    id: 1,
    icon: bankNote,
    title: 'Total Transaction Value',
    amount: '8,134,960',
  },
  {
    id: 2,
    icon: inflowIcon,
    title: 'Total Inflow Value',
    amount: '8,134,960',
  },
  {
    id: 3,
    icon: outflowIcon,
    title: 'Total Outflow Value',
    amount: '8,134,960',
  },
];

export const userMgt = [
  {
    title: 'Total Account Created',
    amount: '8,134,960',
  },
  {
    title: 'Total Business Accounts',
    amount: '8,134,960',
  },
  {
    title: 'Total Freelancer accounts',
    amount: '8,134,960',
  },
];

export const cardMgt = [
  {
    title: 'Total Number of Cards',
    amount: 29392,
  },
  {
    title: 'Active Virtual Cards',
    amount: 17282,
  },
  {
    title: 'Active Physical Cards',
    amount: 18191,
  },
];

export const transHeader = [
  'Date',
  'Account',
  'Transaction Ref',
  'categories',
  'Amount',
  'Transaction mode',
  'Status',
  '',
];

export const transData = [
  {
    date: '17 Oct,2023',
    account: 'CrestPeak Ventures',
    transRef: 'FM2022-TRANS-IPDE0001',
    categories: 'Debit',
    amount: '290,000',
    mode: 'Physical POS',
    status: 'Successful',
  },
  {
    date: '17 Oct,2023',
    account: 'CrestPeak Ventures',
    transRef: 'FM2022-TRANS-IPDE0001',
    categories: 'Debit',
    amount: '290,000',
    mode: 'Physical POS',
    status: 'Pending',
  },
  {
    date: '17 Oct,2023',
    account: 'CrestPeak Ventures',
    transRef: 'FM2022-TRANS-IPDE0001',
    categories: 'Debit',
    amount: '290,000',
    mode: 'Physical POS',
    status: 'Decline',
  },
];

export const accountAction = [
  {
    title: 'View Profile',
    icon: profileIcon,
  },
  {
    title: 'Suspend Account',
    icon: userIcon,
  },
  {
    title: 'Block Account',
    icon: blockIcon,
  },
];

export const chats = [
  {
    name: 'coder dannie',
    time: '3:30pm',
  },
  {
    name: 'coder dannie',
    time: '3:30pm',
  },
  {
    name: 'coder dannie',
    time: '3:30pm',
  },
];

export const profileData = [
  {
    id: 1,
    title: 'Odogwu Kelon',
    icon: personIcon,
  },

  {
    id: 2,
    title: 'LogOut',
    icon: logout,
  },
];

export const businessLayer = [
  'Total Bus. Reg Request ',
  'Pending  Bus. Reg Request ',
  'Approved  Bus. Reg Request ',
];

export const businessFil = [
  {
    id: 1,
    name: 'All',
    value: '',
  },
  {
    id: 2,
    name: 'Approved',
    value: 'APPROVED',
  },
  {
    id: 3,
    name: 'Pending',
    value: 'PENDING',
  },
  {
    id: 4,
    name: 'In Review',
    value: 'IN_REVIEW',
  },
];

export const businessAction = [
  {
    title: 'View Business account',
    icon: profileIcon,
  },
  {
    title: 'Edit Uploaded Details',
    icon: userIcon,
  },

  {
    title: 'Review Request',
    icon: userIcon,
  },
];

export const businessProfileTab = [
  'Virtual card',
  'Physical Card',
  'Bank Account',
  'Identities',
];

export const pendingTab = [
  'Submitted Registration Information',
  'CAC Document Upload',
];
export const userMgtStatus = [
  {
    name: 'All',
    value: '',
  },
  { name: 'Freelance', value: 'FREELANCE' },
  { name: 'Registered', value: 'REGISTERED' },
  { name: 'Unregistered', value: 'UNREGISTERED' },
];
export const allUsersHeader = [
  'Name',
  'Email',
  'Phone Number',
  'Account Type',
  'Status',
  '',
];

export const allUserActions = [
  {
    title: 'View Profile',
    icon: profileIcon,
  },
  {
    title: 'Edit User Details',
  },
  {
    title: 'View transaction history',
    icon: transaction,
  },
  {
    title: 'Suspend Account',
    icon: userIcon,
  },
  {
    title: 'Block Account',
    icon: blockIcon,
  },
];

export const categories = [
  { name: 'All', value: '' },
  { name: 'Airtime', value: 'AIRTIME' },
  { name: 'Data', value: 'DATA' },
  { name: 'Cable', value: 'CABLE' },
  { name: 'Electricity', value: 'ELECTRICITY' },
  { name: 'Inflow', value: 'INFLOW' },
  { name: 'Outflow', value: 'OUTFLOW' },
  // { name: 'Transfer', value: 'TRANSFER' },
  { name: 'Invoice Sub', value: 'INVOICE SUBSCRIPTION' },
  { name: 'Saving', value: 'SAVING' },
  { name: 'Business Reg', value: 'BUSINESS REGISTRATION FEE' },
  { name: 'Card Funding', value: 'CARD FUNDING' },
  { name: 'Card Unloading', value: 'CARD UNLOADING' },
];

export const cardCategories = [
  { name: 'All Categories', value: '' },
  { name: 'Debit', value: 'DEBIT' },
  { name: 'Credit', value: 'CREDIT' },
  { name: 'Bill Payment', value: 'BILL PAYMENT' },
  { name: 'Charges', value: 'CHARGES' },
  { name: 'Airtime', value: 'AIRTIME' },
];

export const directorDetails = [
  {
    firstName: 'Oyinkansola',
    lastName: 'Emmanuel',
    phone: '09061259758',
    email: 'oyinkansolababalola@gmail.com',
    address: 'Dolphin estate, Ikoyi, Lagos state.',
    nin: '1234567696',
    bvn: '4237850304',
  },
  {
    firstName: 'Babatunde',
    lastName: 'Dayo',
    phone: '09061259758',
    email: 'babatundedayo@gmail.com',
    address: 'Dolphin estate, Ikoyi, Lagos state.',
    nin: '1234567696',
    bvn: '4237850304',
  },
  {
    firstName: 'Oyinkansola',
    lastName: 'Emmanuel',
    phone: '09061259758',
    email: 'oyinkansolababalola@gmail.com',
    address: 'Dolphin estate, Ikoyi, Lagos state.',
    nin: '1234567696',
    bvn: '4237850304',
  },
];

export const regBusAction = [
  { name: 'View Business account', img: personIcon },
  { name: 'Edit Uploaded Details', img: userIcon },
  { name: 'Review Request', img: userIcon },
];

export const bankImgs = [
  { name: '9PSB', img: '/assets/9PSB.jpg' },
  { name: 'LENCO', img: '/assets/LENCO.png' },
  { name: 'TITAN', img: '/assets/TITAN.png' },
];

export const savingsLayer = [
  {
    id: 1,
    title: 'Total savings plans ',
    amount: '23,349',
  },
  {
    id: 2,
    title: 'Total amount saved',
    amount: '₦ 15,000,000',
  },
  {
    id: 3,
    title: 'Total interest earned ',
    amount: '₦ 15,000,000',
  },
];

export const savingsHeader = ['Name', 'Savings Plan', 'Savings Status', ''];

export const SavingsData = [
  {
    name: 'Emily Johnson',
    plan: 'Daily Plan',
    status: 'Active',
    view: 'View More',
  },

  {
    name: 'Emily Johnson',
    plan: 'Daily Plan',
    status: 'Active',
    view: 'View More',
  },

  {
    name: 'Emily Johnson',
    plan: 'Daily Plan',
    status: 'Matured',
    view: 'View More',
  },

  {
    name: 'Emily Johnson',
    plan: 'Daily Plan',
    status: 'Withdrawn',
    view: 'View More',
  },

  {
    name: 'Emily Johnson',
    plan: 'Daily Plan',
    status: 'Withdrawn',
    view: 'View More',
  },
  {
    name: 'Emily Johnson',
    plan: 'Daily Plan',
    status: 'Active',
    view: 'View More',
  },

  {
    name: 'Emily Johnson',
    plan: 'Daily Plan',
    status: 'Active',
    view: 'View More',
  },
  {
    name: 'Emily Johnson',
    plan: 'Daily Plan',
    status: 'Inactive',
    view: 'View More',
  },

  {
    name: 'Emily Johnson',
    plan: 'Daily Plan',
    status: 'Active',
    view: 'View More',
  },
];

export const transactionHeader = [
  'Date',
  'Account',
  'Transaction Ref',
  'Category',
  'Amount',
  'Status',
  '',
];

export const transactionData = [
  {
    date: '5 May,2024',
    account: 'Emily Johnson',
    transRef: 'FM2022-TRANS-IPDE0001',
    category: 'Credit',
    amount: '₦ 50,000',
    status: 'Successful',
  },

  {
    date: '5 May,2024',
    account: 'Emily Johnson',
    transRef: 'FM2022-TRANS-IPDE0001',
    category: 'Credit',
    amount: '₦ 50,000',
    status: 'Successful',
  },
  {
    date: '5 May,2024',
    account: 'Emily Johnson',
    transRef: 'FM2022-TRANS-IPDE0001',
    category: 'Credit',
    amount: '₦ 50,000',
    status: 'Successful',
  },
];

export const savingsAction = [
  {
    title: 'View Transaction Details',
    icon: profileIcon,
  },
];

export const cardDetailsValues = [
  {
    icon: bankNote,
    title: 'Total Transaction Value',
    amount: '8,134,960',
  },
  {
    icon: inflowIcon,
    title: 'Total Inflow Value',
    amount: '8,134,960',
  },
  {
    icon: outflowIcon,
    title: 'Total Outflow Value',
    amount: '8,134,960',
  },
];

export const filters = [
  {
    id: 1,
    name: 'Yesterday',
    value: '1',
  },
  {
    id: 2,
    name: 'Last Week',
    value: '7',
  },
  {
    id: 3,
    name: 'Last 14 days',
    value: '14',
  },
  {
    id: 4,
    name: 'Last 30 days',
    value: '30',
  },
];

export const quickActionAnalytics = [
  {
    id: 1,
    title: 'Total Accounts',
    amount: '23,349',
  },
  {
    id: 2,
    title: 'Total Verified Accounts',
    amount: '23,349',
  },
  {
    id: 3,
    title: 'Total unverified accounts',
    amount: '23,349',
  },
];

export const statFilter = [
  {
    id: 1,
    name: 'All',
    value: '',
  },
  {
    id: 2,
    name: 'Verified',
    value: 'VERIFIED',
  },
  {
    id: 3,
    name: 'Unverified',
    value: 'UNVERIFIED',
  },
  {
    id: 4,
    name: 'Suspended',
    value: 'SUSPENDED',
  },
  {
    id: 5,
    name: 'Blocked',
    value: 'BLOCKED',
  },
];
export const transactionFilter = [
  {
    id: 1,
    name: 'All',
    value: '',
  },
  {
    id: 2,
    name: 'Successful',
    value: 'SUCCESSFUL',
  },
  {
    id: 3,
    name: 'Failed',
    value: 'FAILED',
  },
  {
    id: 4,
    name: 'Pending',
    value: 'PENDING',
  },
  {
    id: 5,
    name: 'Reversed',
    value: 'REVERSED',
  },
];

export const busRegFilter = [
  {
    id: 1,
    name: 'All',
    value: '',
  },
  {
    id: 2,
    name: 'Approved',
    value: 'APPROVED',
  },
  {
    id: 3,
    name: 'Pending',
    value: 'PENDING',
  },
  {
    id: 4,
    name: 'In review',
    value: 'IN REVIEW',
  },
];

export const busRequestAnalytics = [
  {
    id: 1,
    title: 'Total Bus. Reg Request',
    amount: '23,349',
  },
  {
    id: 2,
    title: 'Pending  Bus. Reg Request',
    amount: '23,349',
  },
  {
    id: 3,
    title: 'Approved  Bus. Reg Request',
    amount: '23,349',
  },
];

export const transStatus = [
  {
    id: 1,
    name: 'All',
    value: '',
  },
  {
    id: 2,
    name: 'Successful',
    value: 'SUCCESSFUL',
  },
  {
    id: 3,
    name: 'Pending',
    value: 'PENDING',
  },
  {
    id: 4,
    name: 'Failed',
    value: 'FAILED',
  },
  {
    id: 5,
    name: 'Reversed',
    value: 'REVERSED',
  },
];

export const transCat = [
  {
    id: 1,
    name: 'All',
    value: '',
  },
  {
    id: 2,
    name: 'Debit',
    value: 'DEBIT',
  },
  {
    id: 3,
    name: 'Credit',
    value: 'CREDIT',
  },
  {
    id: 4,
    name: 'Bill payment',
    value: 'BILL PAYMENT',
  },
  {
    id: 5,
    name: 'Charges',
    value: 'CHARGES',
  },
  {
    id: 4,
    name: 'Airtime',
    value: 'AIRTIME',
  },
];

export const cardsHeader = ['Name', 'Email', 'Status', ''];

export const cardsData = [
  {
    name: 'Enterprise Edge',
    email: 'SwiftPeakEnterprises@gmail.com',
    status: 'Approved',
  },

  {
    name: 'Enterprise Edge',
    email: 'SwiftPeakEnterprises@gmail.com',
    status: 'Approved',
  },
  {
    name: 'Enterprise Edge',
    email: 'SwiftPeakEnterprises@gmail.com',
    status: 'Approved',
  },
  {
    name: 'Enterprise Edge',
    email: 'SwiftPeakEnterprises@gmail.com',
    status: 'Pending',
  },

  {
    name: 'Enterprise Edge',
    email: 'SwiftPeakEnterprises@gmail.com',
    status: 'Dispatched',
  },
  {
    name: 'Enterprise Edge',
    email: 'SwiftPeakEnterprises@gmail.com',
    status: 'Dispatched',
  },
  {
    name: 'Enterprise Edge',
    email: 'SwiftPeakEnterprises@gmail.com',
    status: 'Pending',
  },

  {
    name: 'Enterprise Edge',
    email: 'SwiftPeakEnterprises@gmail.com',
    status: 'Approved',
  },

  {
    name: 'Enterprise Edge',
    email: 'SwiftPeakEnterprises@gmail.com',
    status: 'Processing',
  },
];

export const cardsAction = [
  {
    title: 'View Card details ',
    icon: viewIcon,
  },

  {
    title: 'Review Request ',
    icon: viewIcon,
  },

  {
    title: 'Approve Request ',
    icon: viewIcon,
  },
];

export const type = ['All', 'Physical Card', 'Virtual Card'];

export const invoiceMgt = [
  'Total invoices',
  'Total paid invoices',
  'Total Overdue Invoices ',
];

export const invoiceHeader = [
  'Date',
  'Client name',
  'Invoice number',
  'Total Amount',
  'Amount Paid',
  'Amount due',
  'Payment status',
  '',
];

export const invoiceActions = [
  {
    title: 'View Invoice details',
    icon: viewLogo,
  },
  {
    title: 'Audit trail',
    icon: auditIcon,
  },
  {
    title: 'Invoice Subscription',
    icon: moneyIcon,
  },
];

export const auditInvoice = [
  'Total invoice bundle purchased',
  'Total invoice bundle Used',
];

export const rolesActions = [
  {
    title: 'View Details',
    icon: profileIcon,
  },
  {
    title: 'Delete Role',
    icon: deleteIcon,
  },
];

export const dashboardPermissionLabel = {
  viewDashboardSettings: 'View dashboard and dashboard settings',
};
export const accountPermissionLabel = {
  viewAccount: 'View User/Business/Freelancer Account',
  viewUserBusinessSubAccount: 'View User/Business/Freelancer Account',
  updateViewUserBusAccount: 'Update View User/Business/Freelancer Account',
  deleteViewUserAccount: 'Delete View User/Business/Freelancer Account',
};

export const transactionPermissionLabel = {
  viewManageTransactionSetting: 'View and Manage Transactions',
};

export const requestsPermissionLabel = {
  approveBusinessReg: 'Approve Business registration requests',
  approveOrDeclineCardRequests: 'Approve OR DECLINE Card requests',
  approveCardUnsuspension: 'Approve Card Unsuspension request',
};

export const managementPermissionLabel = {
  viewAndManageBusinessReg: 'View and Manage Business registrations request',
  viewManageInvoice: 'View and manage Invoice',
  viewManagePos: 'View and Manage POS',
  viewManageCards: 'View and Manage Cards',
  viewManageSaving: 'View and Manage Saving',
  viewManageEPMS: 'View and Manage EPMS',
};

export const generalPermissionLabel = {
  viewNotifications: 'View Notifications',
  viewSystemSettings: 'View System Settings',
  editSystemSettings: 'Edit System Settings',
};
