import React, { useEffect, useState } from "react";
import { Box, Flex, Text, VStack, Collapse, Image } from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { siderBarData, general } from "../../components/common/constant";
import { useLogOut } from "../../utils/helper";
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
} from "@chakra-ui/react";

const SideDrawer = ({ isOpen, onClose }) => {
  const logout = useLogOut();
  const [isLoading, setIsLoading] = useState(false);

  const action = () => {
    setIsLoading(true);
    setTimeout(() => {
      logout();
      setIsLoading(false);
    }, 1000);
  };

  const [openSubItems, setOpenSubItems] = useState({});
  const { pathname } = useLocation();

  const handleToggleSubItem = (name) => {
    setOpenSubItems((prevState) => {
      const newOpenSubItems = {};

      Object.keys(prevState).forEach((item) => {
        newOpenSubItems[item] = false;
      });

      const activeParentItem = siderBarData.find((item) =>
        pathname.includes(item.path),
      )?.name;

      newOpenSubItems[activeParentItem] = true;

      if (name) newOpenSubItems[name] = !prevState[name];

      return newOpenSubItems;
    });
  };
  useEffect(() => {
    handleToggleSubItem(null);
  }, [pathname]);

  const navigate = useNavigate();

  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent color="#000">
        <DrawerBody p={0} overflowY="scroll">
          <Flex
            flexDir="column"
            justifyContent="space-between"
            // position={'fixed'}
            zIndex="5"
            w="full"
            bg="#fff"
          >
            <Box h="100vh" overflowY="scroll">
              <Flex
                bg="#fff"
                pos="sticky"
                px="24px"
                zIndex={55}
                pt="30px"
                top="0"
                pb="25px"
                borderBottom="1px solid #314169"
                justifyContent="space-between"
                align="center"
              >
                <Image
                  src="/assets/logo.png"
                  w="160px"
                  h="55px"
                  objectFit="contain"
                />
              </Flex>

              <Box mx="20px" mt="39px">
                <Box>
                  {siderBarData?.map((item, i) => {
                    return (
                      <VStack
                        key={i}
                        align="stretch"
                        mb="15px"
                        className={
                          !pathname.includes(item?.path) && "parent_nav"
                        }
                        gap={0}
                      >
                        <Flex
                          align="center"
                          p={2}
                          pt={3}
                          cursor="pointer"
                          onClick={() =>
                            item.sub
                              ? navigate(item.sub[0].path)
                              : navigate(item.path)
                          }
                          bg={
                            pathname.includes(item.path) ||
                            openSubItems[item.name]
                              ? "#F2F2F2"
                              : "transparent"
                          }
                          fontWeight={
                            pathname.includes(item.path) ||
                            openSubItems[item.name]
                              ? "500"
                              : "400"
                          }
                          color={
                            pathname.includes(item.path) ||
                            openSubItems[item.name]
                              ? "#0F172B"
                              : "#0F172B"
                          }
                          _hover={{
                            bg: "#F2F2F2",
                            color: "#0F172B",
                            fontWeight: "500",
                          }}
                          h="45px"
                          borderRadius="10px"
                          transition=".3s ease-in-out"
                          position="relative"
                          gap="24px"
                        >
                          <Box>
                            <Image src={item.icon} />
                          </Box>
                          <Box>
                            <Text fontSize="12px">{item.name}</Text>
                          </Box>

                          {item.sub && (
                            <Flex
                              justifyContent="flex-end"
                              w="full"
                              color={
                                openSubItems[item.name] ? "#101928" : "#B4B4B4"
                              }
                            >
                              {openSubItems[item.name] ? (
                                <IoIosArrowUp />
                              ) : (
                                <IoIosArrowDown />
                              )}
                            </Flex>
                          )}
                        </Flex>

                        {item.sub && (
                          <Collapse in={openSubItems[item.name]}>
                            <VStack
                              pl={3}
                              align="stretch"
                              borderBottomRadius={4}
                              pb="2"
                              gap={3}
                              pt={4}
                            >
                              {item.sub.map((subItem) => (
                                <Link
                                  key={subItem.name}
                                  to={subItem.path}
                                  style={{
                                    padding: "4px 0",
                                    fontSize: "12px",
                                    textDecoration: "none",
                                    color: pathname.includes(subItem.path)
                                      ? "#101928"
                                      : "#b4b4b4",
                                    fontWeight: pathname.includes(subItem.path)
                                      ? "500"
                                      : "400",
                                  }}
                                >
                                  <Flex gap="10px">
                                    <Box>
                                      <Image src={subItem.icon} />
                                    </Box>
                                    <Box> {subItem.name}</Box>
                                  </Flex>
                                </Link>
                              ))}
                            </VStack>
                          </Collapse>
                        )}
                      </VStack>
                    );
                  })}
                </Box>
                <Box mt="20px">
                  <Text
                    mb="28px"
                    fontSize="14px"
                    fontWeight={500}
                    color="#98A2B3"
                  >
                    General
                  </Text>
                  {general?.map((item, i) => {
                    return (
                      <VStack
                        key={i}
                        align="stretch"
                        mb="20px"
                        className={
                          !pathname.includes(item?.path) && "parent_nav"
                        }
                        gap={0}
                      >
                        <Flex
                          align="center"
                          p={2}
                          pt={3}
                          cursor="pointer"
                          onClick={() =>
                            item.sub
                              ? navigate(item.sub[0].path)
                              : navigate(item.path)
                          }
                          bg={
                            pathname.includes(item.path)
                              ? "#F2F2F2"
                              : "transparent"
                          }
                          fontWeight={
                            pathname.includes(item.path) ? "500" : "400"
                          }
                          color={
                            pathname.includes(item.path) ? "#0F172B" : "#0F172B"
                          }
                          _hover={{
                            bg: "#E9E9E9",
                            color: "#0F172B",
                          }}
                          h="45px"
                          borderRadius="10px"
                          transition=".3s ease-in-out"
                          position="relative"
                          gap="24px"
                        >
                          <Box>
                            <Image src={item.icon} />
                          </Box>
                          <Box>
                            <Text fontSize="12px">{item.name}</Text>
                          </Box>
                        </Flex>
                      </VStack>
                    );
                  })}
                </Box>

                <Box
                  mt="40px"
                  p={2}
                  fontSize="14px"
                  align="center"
                  py="30px"
                  fontWeight={400}
                  cursor="pointer"
                  onClick={action}
                >
                  {isLoading ? (
                    <Flex gap="8px" color="red" align="center">
                      <Spinner size="sm" /> Logging Out
                    </Flex>
                  ) : (
                    <Flex gap="8px" align="center" color="#0F172B">
                      Log Out
                    </Flex>
                  )}
                </Box>
              </Box>
            </Box>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default React.memo(SideDrawer);
