import axiosInstance, { uploadInstance } from '../axiosInstance';
import * as API from '../url';

export const getAllUsers = async ({ queryKey }) => {
  const [, start, end, status, page, limit, searchTerm] = queryKey;
  const res = await axiosInstance.get(
    `${API.ALL_USERS}?startDate=${start}&endDate=${end}&status=${status}&page=${page}&limit=${limit}&searchTerm=${searchTerm}`
  );
  return res.data;
};

export const getTransactions = async ({ queryKey }) => {
  const [, start, end, category, status, page, limit, searchTerm] = queryKey;

  let query = '';
  if (start) {
    query = query + `startDate=${start}&`;
  }

  if (end) {
    query = query + `endDate=${end}&`;
  }
  if (category) {
    query = query + `category=${category}&`;
  }
  if (status) {
    query = query + `status=${status}&`;
  }
  if (page) {
    query = query + `page=${page}&`;
  }

  if (limit) {
    query = query + `limit=${limit}&`;
  }
  if (searchTerm) {
    query = query + `searchTerm=${searchTerm}&`;
  }

  const res = await axiosInstance.get(`${API.ADMIN_TRANSACTIONS}?${query}`);
  return res.data;
};

export const getBusinessReg = async ({ queryKey }) => {
  const [, start, end, status, page, limit, searchTerm] = queryKey;
  const res = await axiosInstance.get(
    `${API.BUSINESS_REG}?startDate=${start}&endDate=${end}&status=${status}&page=${page}&limit=${limit}&searchTerm=${searchTerm}`
  );
  return res.data;
};

export const getOneBusinessReg = async ({ queryKey }) => {
  const [, id] = queryKey;
  const res = await axiosInstance.get(
    `${API.BUSINESS_REG}/${id}/business-account`
  );
  return res.data;
};

export const getTransactionsSummary = async () => {
  const res = await axiosInstance.get(API.ADMIN_TRANSACTIONS_SUMMARY);
  return res.data;
};

export const getBusinessRegCount = async () => {
  const res = await axiosInstance.get(API.BUSINESS_REG_COUNT);
  return res.data;
};

export const getUsersCount = async () => {
  const res = await axiosInstance.get(API.ALL_USERS_COUNT);
  return res.data;
};

export const getAdmin = async () => {
  const res = await axiosInstance.get(API.GET_ADMIN);
  return res.data;
};

export const getOneUser = async ({ queryKey }) => {
  const [, userRef, businessId] = queryKey;
  const res = await axiosInstance.get(
    `${API.GET_ONE_USER}/${userRef}/${businessId}/profile`
  );
  return res.data;
};

export const editOneUser = async ({ query, body }) => {
  const res = await axiosInstance.patch(API.EDIT_ONE_USER(query), body);
  return res.data;
};

export const approveBusiness = async ({ query, body }) => {
  const res = await uploadInstance.post(API.APPROVE_BUS(query), body);
  return res.data;
};

export const editUser = async ({ query, body }) => {
  const res = await uploadInstance.patch(API.EDIT_ONE_USER(query), body);
  return res.data;
};

export const editUserDir = async ({ query, body }) => {
  const res = await uploadInstance.patch(API.EDIT_USER_DIR(query), body);
  return res.data;
};
